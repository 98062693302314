import axios from "axios";
import fileDownload from "js-file-download";

const { useState } = require("react")

const Home = () => {
    const [name, setName] = useState(``);
    const [url, setUrl] = useState(``);
    const [startTime, setStartTime] = useState(``);
    const [duration, setDuration] = useState(``);
    const [isLoading, setIsLoading] = useState(false);

    const clipVideo = async () => {
        const endpoint = `/api/download?url=${url}&start=${startTime}&duration=${duration}`
        setIsLoading(true);
        try {
            const {data} = await axios.get(endpoint, {responseType: 'blob'});
            fileDownload(data, `${name}.mp4`);
        } catch (err) {
            console.log(err)
        }
        setIsLoading(false);
    }

    const clearDownloads = async () => {
        const endpoint = `/api/clear-downloads`;
        await axios.delete(endpoint);
    }

    return (
        <div>
            <div>
                <h1>Youtube Clipper</h1>
                <input placeholder="Clip Name" value={name} onChange={e => setName(e.target.value)}/>
                <input placeholder="URL" value={url} onChange={e => setUrl(e.target.value)}/>
                <input placeholder="Start Time 00:00:00" value={startTime} onChange={e => setStartTime(e.target.value)}/>
                <input placeholder="Duration" value={duration} onChange={e => setDuration(e.target.value)}/>
                {isLoading ? <div>Processing...</div> : <input type="submit" value="Clip" onClick={clipVideo} />}
            </div>
            <div>
                <button onClick={clearDownloads}>Clear downloads</button>
            </div>
        </div>
    )
}

export default Home;